import { abortSignalWrapper } from "@/js/utils/signal";

function getUploadXhrRequest({ url }, { resolve, reject }, { onProgress, signal }) {
  const xhr = new XMLHttpRequest();
  xhr.onprogress = onProgress;
  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200 || xhr.status === 204 || xhr.status === 201) {
        resolve(url);
      } else {
        reject("Could not upload file.");
      }
    }
  };
  signal?.addEventListener("abort", () => {
    xhr.abort();
  });
  return xhr;
}

function uploadFileToRegularS3(xhr, { url, fields }, file) {
  xhr.open("POST", url);
  xhr.setRequestHeader("X-Container-Meta-Access-Control-Allow-Origin", "*");
  const postData = new FormData();
  for (const key in fields) postData.append(key, fields[key]);
  postData.append("file", file);
  xhr.send(postData);
}

function uploadFileToGoogleS3(xhr, { url, fields }, file) {
  xhr.open("POST", url);
  const postData = new FormData();
  for (const key in fields) postData.append(key, fields[key]);
  postData.append("file", file);
  xhr.send(postData);
}

function uploadFileToAzureS3(xhr, { url }, file) {
  xhr.open("PUT", url);
  xhr.setRequestHeader("X-Container-Meta-Access-Control-Allow-Origin", "*");
  xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
  xhr.send(file);
}

export async function uploadFileToS3(
  file,
  { presigned_url_data: uploadData, client_type: clientType },
  { onProgress, signal } = {},
) {
  return new Promise((resolve, reject) => {
    const xhr = getUploadXhrRequest(uploadData, { resolve, reject }, { onProgress, signal });

    switch (clientType) {
      case "AWS":
      case "MINIO":
        uploadFileToRegularS3(xhr, uploadData, file);
        break;
      case "GOOGLE":
        uploadFileToGoogleS3(xhr, uploadData, file);
        break;
      case "AZURE":
        uploadFileToAzureS3(xhr, uploadData, file);
        break;
      default:
        reject("Unknown client type");
    }
  });
}

export async function uploadFile(file, uploadData, { signal, onProgress = () => {} }) {
  return await abortSignalWrapper(signal, uploadFileToS3(file, uploadData, { onProgress, signal }));
}
