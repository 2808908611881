/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ContentTypeIn from '../model/ContentTypeIn';
import GeneratePresignedUrlOut from '../model/GeneratePresignedUrlOut';
import MyUserIn from '../model/MyUserIn';
import MyUserOut from '../model/MyUserOut';
import PasswordIn from '../model/PasswordIn';
import PendingUserOut from '../model/PendingUserOut';
import PreferencesIn from '../model/PreferencesIn';
import PreferencesOut from '../model/PreferencesOut';
import PublicUserOut from '../model/PublicUserOut';
import TokenOut from '../model/TokenOut';

/**
* User service.
* @module api/UserApi
* @version 1.0.0
*/
export default class UserApi {

    /**
    * Constructs a new UserApi. 
    * @alias module:api/UserApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     *  
     * @param {module:model/PasswordIn} passwordIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    changePasswordWithHttpInfo(passwordIn) {
      let postBody = passwordIn;
      // verify the required parameter 'passwordIn' is set
      if (passwordIn === undefined || passwordIn === null) {
        throw new Error("Missing the required parameter 'passwordIn' when calling changePassword");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/user/password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/PasswordIn} passwordIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    changePassword(passwordIn) {
      return this.changePasswordWithHttpInfo(passwordIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete PendingUser
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deletePendinguserWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deletePendinguser");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/users/pending/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Delete PendingUser
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deletePendinguser(id) {
      return this.deletePendinguserWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} input 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PublicUserOut} and HTTP response
     */
    findUserWithHttpInfo(input) {
      let postBody = null;
      // verify the required parameter 'input' is set
      if (input === undefined || input === null) {
        throw new Error("Missing the required parameter 'input' when calling findUser");
      }

      let pathParams = {
      };
      let queryParams = {
        'input': input
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PublicUserOut;
      return this.apiClient.callApi(
        '/api/users/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} input 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PublicUserOut}
     */
    findUser(input) {
      return this.findUserWithHttpInfo(input)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/ContentTypeIn} contentTypeIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GeneratePresignedUrlOut} and HTTP response
     */
    generatePresignedUrlProfilePictureWithHttpInfo(contentTypeIn) {
      let postBody = contentTypeIn;
      // verify the required parameter 'contentTypeIn' is set
      if (contentTypeIn === undefined || contentTypeIn === null) {
        throw new Error("Missing the required parameter 'contentTypeIn' when calling generatePresignedUrlProfilePicture");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = GeneratePresignedUrlOut;
      return this.apiClient.callApi(
        '/api/user/picture', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/ContentTypeIn} contentTypeIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GeneratePresignedUrlOut}
     */
    generatePresignedUrlProfilePicture(contentTypeIn) {
      return this.generatePresignedUrlProfilePictureWithHttpInfo(contentTypeIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MyUserOut} and HTTP response
     */
    getMyUserWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = MyUserOut;
      return this.apiClient.callApi(
        '/api/user/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MyUserOut}
     */
    getMyUser() {
      return this.getMyUserWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get PendingUser
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PendingUserOut} and HTTP response
     */
    getPendinguserWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getPendinguser");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PendingUserOut;
      return this.apiClient.callApi(
        '/api/users/pending/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get PendingUser
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PendingUserOut}
     */
    getPendinguser(id) {
      return this.getPendinguserWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/MyUserIn} myUserIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MyUserOut} and HTTP response
     */
    partialUpdateMyUserWithHttpInfo(myUserIn) {
      let postBody = myUserIn;
      // verify the required parameter 'myUserIn' is set
      if (myUserIn === undefined || myUserIn === null) {
        throw new Error("Missing the required parameter 'myUserIn' when calling partialUpdateMyUser");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = MyUserOut;
      return this.apiClient.callApi(
        '/api/user/', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/MyUserIn} myUserIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MyUserOut}
     */
    partialUpdateMyUser(myUserIn) {
      return this.partialUpdateMyUserWithHttpInfo(myUserIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TokenOut} and HTTP response
     */
    retrieveMyTokenWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TokenOut;
      return this.apiClient.callApi(
        '/api/user/token', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TokenOut}
     */
    retrieveMyToken() {
      return this.retrieveMyTokenWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TokenOut} and HTTP response
     */
    rotateMyTokenWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TokenOut;
      return this.apiClient.callApi(
        '/api/user/token', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TokenOut}
     */
    rotateMyToken() {
      return this.rotateMyTokenWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    sendInvitationPendingUserWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling sendInvitationPendingUser");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/users/pending/{id}/send', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    sendInvitationPendingUser(id) {
      return this.sendInvitationPendingUserWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {module:model/PreferencesIn} preferencesIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PreferencesOut} and HTTP response
     */
    updateMyPreferencesWithHttpInfo(preferencesIn) {
      let postBody = preferencesIn;
      // verify the required parameter 'preferencesIn' is set
      if (preferencesIn === undefined || preferencesIn === null) {
        throw new Error("Missing the required parameter 'preferencesIn' when calling updateMyPreferences");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = PreferencesOut;
      return this.apiClient.callApi(
        '/api/user/preferences', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {module:model/PreferencesIn} preferencesIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PreferencesOut}
     */
    updateMyPreferences(preferencesIn) {
      return this.updateMyPreferencesWithHttpInfo(preferencesIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
