/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PreferencesOut from './PreferencesOut';
import UserHelpers from './UserHelpers';
import UserIntro from './UserIntro';

/**
 * The MyUserOut model module.
 * @module model/MyUserOut
 * @version 1.0.0
 */
class MyUserOut {
    /**
     * Constructs a new <code>MyUserOut</code>.
     * @alias module:model/MyUserOut
     * @param id {String} 
     * @param username {String} 
     * @param email {String} 
     * @param dateJoined {Date} 
     * @param pictureUrl {String} 
     * @param firstName {String} 
     * @param lastName {String} 
     * @param jobTitle {String} 
     * @param company {String} 
     * @param bio {String} 
     * @param helpers {module:model/UserHelpers} 
     * @param intro {module:model/UserIntro} 
     * @param cgu {Boolean} 
     * @param newsletter {Boolean} 
     * @param preferences {module:model/PreferencesOut} 
     */
    constructor(id, username, email, dateJoined, pictureUrl, firstName, lastName, jobTitle, company, bio, helpers, intro, cgu, newsletter, preferences) { 
        
        MyUserOut.initialize(this, id, username, email, dateJoined, pictureUrl, firstName, lastName, jobTitle, company, bio, helpers, intro, cgu, newsletter, preferences);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, username, email, dateJoined, pictureUrl, firstName, lastName, jobTitle, company, bio, helpers, intro, cgu, newsletter, preferences) { 
        obj['id'] = id;
        obj['username'] = username;
        obj['email'] = email;
        obj['date_joined'] = dateJoined;
        obj['picture_url'] = pictureUrl;
        obj['first_name'] = firstName;
        obj['last_name'] = lastName;
        obj['job_title'] = jobTitle;
        obj['company'] = company;
        obj['bio'] = bio;
        obj['helpers'] = helpers;
        obj['intro'] = intro;
        obj['cgu'] = cgu;
        obj['newsletter'] = newsletter;
        obj['preferences'] = preferences;
    }

    /**
     * Constructs a <code>MyUserOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MyUserOut} obj Optional instance to populate.
     * @return {module:model/MyUserOut} The populated <code>MyUserOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MyUserOut();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('date_joined')) {
                obj['date_joined'] = ApiClient.convertToType(data['date_joined'], 'Date');
            }
            if (data.hasOwnProperty('picture')) {
                obj['picture'] = ApiClient.convertToType(data['picture'], 'String');
            }
            if (data.hasOwnProperty('picture_url')) {
                obj['picture_url'] = ApiClient.convertToType(data['picture_url'], 'String');
            }
            if (data.hasOwnProperty('first_name')) {
                obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String');
            }
            if (data.hasOwnProperty('last_name')) {
                obj['last_name'] = ApiClient.convertToType(data['last_name'], 'String');
            }
            if (data.hasOwnProperty('job_title')) {
                obj['job_title'] = ApiClient.convertToType(data['job_title'], 'String');
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = ApiClient.convertToType(data['company'], 'String');
            }
            if (data.hasOwnProperty('bio')) {
                obj['bio'] = ApiClient.convertToType(data['bio'], 'String');
            }
            if (data.hasOwnProperty('helpers')) {
                obj['helpers'] = UserHelpers.constructFromObject(data['helpers']);
            }
            if (data.hasOwnProperty('intro')) {
                obj['intro'] = UserIntro.constructFromObject(data['intro']);
            }
            if (data.hasOwnProperty('cgu')) {
                obj['cgu'] = ApiClient.convertToType(data['cgu'], 'Boolean');
            }
            if (data.hasOwnProperty('newsletter')) {
                obj['newsletter'] = ApiClient.convertToType(data['newsletter'], 'Boolean');
            }
            if (data.hasOwnProperty('preferences')) {
                obj['preferences'] = PreferencesOut.constructFromObject(data['preferences']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MyUserOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MyUserOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MyUserOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['username'] && !(typeof data['username'] === 'string' || data['username'] instanceof String)) {
            throw new Error("Expected the field `username` to be a primitive type in the JSON string but got " + data['username']);
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }
        // ensure the json data is a string
        if (data['picture'] && !(typeof data['picture'] === 'string' || data['picture'] instanceof String)) {
            throw new Error("Expected the field `picture` to be a primitive type in the JSON string but got " + data['picture']);
        }
        // ensure the json data is a string
        if (data['picture_url'] && !(typeof data['picture_url'] === 'string' || data['picture_url'] instanceof String)) {
            throw new Error("Expected the field `picture_url` to be a primitive type in the JSON string but got " + data['picture_url']);
        }
        // ensure the json data is a string
        if (data['first_name'] && !(typeof data['first_name'] === 'string' || data['first_name'] instanceof String)) {
            throw new Error("Expected the field `first_name` to be a primitive type in the JSON string but got " + data['first_name']);
        }
        // ensure the json data is a string
        if (data['last_name'] && !(typeof data['last_name'] === 'string' || data['last_name'] instanceof String)) {
            throw new Error("Expected the field `last_name` to be a primitive type in the JSON string but got " + data['last_name']);
        }
        // ensure the json data is a string
        if (data['job_title'] && !(typeof data['job_title'] === 'string' || data['job_title'] instanceof String)) {
            throw new Error("Expected the field `job_title` to be a primitive type in the JSON string but got " + data['job_title']);
        }
        // ensure the json data is a string
        if (data['company'] && !(typeof data['company'] === 'string' || data['company'] instanceof String)) {
            throw new Error("Expected the field `company` to be a primitive type in the JSON string but got " + data['company']);
        }
        // ensure the json data is a string
        if (data['bio'] && !(typeof data['bio'] === 'string' || data['bio'] instanceof String)) {
            throw new Error("Expected the field `bio` to be a primitive type in the JSON string but got " + data['bio']);
        }
        // validate the optional field `helpers`
        if (data['helpers']) { // data not null
          UserHelpers.validateJSON(data['helpers']);
        }
        // validate the optional field `intro`
        if (data['intro']) { // data not null
          UserIntro.validateJSON(data['intro']);
        }
        // validate the optional field `preferences`
        if (data['preferences']) { // data not null
          PreferencesOut.validateJSON(data['preferences']);
        }

        return true;
    }


}

MyUserOut.RequiredProperties = ["id", "username", "email", "date_joined", "picture_url", "first_name", "last_name", "job_title", "company", "bio", "helpers", "intro", "cgu", "newsletter", "preferences"];

/**
 * @member {String} id
 */
MyUserOut.prototype['id'] = undefined;

/**
 * @member {String} username
 */
MyUserOut.prototype['username'] = undefined;

/**
 * @member {String} email
 */
MyUserOut.prototype['email'] = undefined;

/**
 * @member {Date} date_joined
 */
MyUserOut.prototype['date_joined'] = undefined;

/**
 * @member {String} picture
 */
MyUserOut.prototype['picture'] = undefined;

/**
 * @member {String} picture_url
 */
MyUserOut.prototype['picture_url'] = undefined;

/**
 * @member {String} first_name
 */
MyUserOut.prototype['first_name'] = undefined;

/**
 * @member {String} last_name
 */
MyUserOut.prototype['last_name'] = undefined;

/**
 * @member {String} job_title
 */
MyUserOut.prototype['job_title'] = undefined;

/**
 * @member {String} company
 */
MyUserOut.prototype['company'] = undefined;

/**
 * @member {String} bio
 */
MyUserOut.prototype['bio'] = undefined;

/**
 * @member {module:model/UserHelpers} helpers
 */
MyUserOut.prototype['helpers'] = undefined;

/**
 * @member {module:model/UserIntro} intro
 */
MyUserOut.prototype['intro'] = undefined;

/**
 * @member {Boolean} cgu
 */
MyUserOut.prototype['cgu'] = undefined;

/**
 * @member {Boolean} newsletter
 */
MyUserOut.prototype['newsletter'] = undefined;

/**
 * @member {module:model/PreferencesOut} preferences
 */
MyUserOut.prototype['preferences'] = undefined;






export default MyUserOut;

