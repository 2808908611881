/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class TimeCursorBehavior.
* @enum {}
* @readonly
*/
export default class TimeCursorBehavior {
    
        /**
         * value: "STICK_TO_RIGHT"
         * @const
         */
        "STICK_TO_RIGHT" = "STICK_TO_RIGHT";

    
        /**
         * value: "STICK_TO_MIDDLE"
         * @const
         */
        "STICK_TO_MIDDLE" = "STICK_TO_MIDDLE";

    
        /**
         * value: "BACK_TO_LEFT"
         * @const
         */
        "BACK_TO_LEFT" = "BACK_TO_LEFT";

    
        /**
         * value: "OVERFLOW"
         * @const
         */
        "OVERFLOW" = "OVERFLOW";

    

    /**
    * Returns a <code>TimeCursorBehavior</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/TimeCursorBehavior} The enum <code>TimeCursorBehavior</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

