/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import UserHelpers from './UserHelpers';
import UserIntro from './UserIntro';

/**
 * The MyUserIn model module.
 * @module model/MyUserIn
 * @version 1.0.0
 */
class MyUserIn {
    /**
     * Constructs a new <code>MyUserIn</code>.
     * @alias module:model/MyUserIn
     */
    constructor() { 
        
        MyUserIn.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MyUserIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MyUserIn} obj Optional instance to populate.
     * @return {module:model/MyUserIn} The populated <code>MyUserIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MyUserIn();

            if (data.hasOwnProperty('job_title')) {
                obj['job_title'] = ApiClient.convertToType(data['job_title'], 'String');
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = ApiClient.convertToType(data['company'], 'String');
            }
            if (data.hasOwnProperty('bio')) {
                obj['bio'] = ApiClient.convertToType(data['bio'], 'String');
            }
            if (data.hasOwnProperty('first_name')) {
                obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String');
            }
            if (data.hasOwnProperty('last_name')) {
                obj['last_name'] = ApiClient.convertToType(data['last_name'], 'String');
            }
            if (data.hasOwnProperty('picture_object_name')) {
                obj['picture_object_name'] = ApiClient.convertToType(data['picture_object_name'], 'String');
            }
            if (data.hasOwnProperty('helpers')) {
                obj['helpers'] = UserHelpers.constructFromObject(data['helpers']);
            }
            if (data.hasOwnProperty('intro')) {
                obj['intro'] = UserIntro.constructFromObject(data['intro']);
            }
            if (data.hasOwnProperty('newsletter')) {
                obj['newsletter'] = ApiClient.convertToType(data['newsletter'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MyUserIn</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MyUserIn</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['job_title'] && !(typeof data['job_title'] === 'string' || data['job_title'] instanceof String)) {
            throw new Error("Expected the field `job_title` to be a primitive type in the JSON string but got " + data['job_title']);
        }
        // ensure the json data is a string
        if (data['company'] && !(typeof data['company'] === 'string' || data['company'] instanceof String)) {
            throw new Error("Expected the field `company` to be a primitive type in the JSON string but got " + data['company']);
        }
        // ensure the json data is a string
        if (data['bio'] && !(typeof data['bio'] === 'string' || data['bio'] instanceof String)) {
            throw new Error("Expected the field `bio` to be a primitive type in the JSON string but got " + data['bio']);
        }
        // ensure the json data is a string
        if (data['first_name'] && !(typeof data['first_name'] === 'string' || data['first_name'] instanceof String)) {
            throw new Error("Expected the field `first_name` to be a primitive type in the JSON string but got " + data['first_name']);
        }
        // ensure the json data is a string
        if (data['last_name'] && !(typeof data['last_name'] === 'string' || data['last_name'] instanceof String)) {
            throw new Error("Expected the field `last_name` to be a primitive type in the JSON string but got " + data['last_name']);
        }
        // ensure the json data is a string
        if (data['picture_object_name'] && !(typeof data['picture_object_name'] === 'string' || data['picture_object_name'] instanceof String)) {
            throw new Error("Expected the field `picture_object_name` to be a primitive type in the JSON string but got " + data['picture_object_name']);
        }
        // validate the optional field `helpers`
        if (data['helpers']) { // data not null
          UserHelpers.validateJSON(data['helpers']);
        }
        // validate the optional field `intro`
        if (data['intro']) { // data not null
          UserIntro.validateJSON(data['intro']);
        }

        return true;
    }


}



/**
 * @member {String} job_title
 */
MyUserIn.prototype['job_title'] = undefined;

/**
 * @member {String} company
 */
MyUserIn.prototype['company'] = undefined;

/**
 * @member {String} bio
 */
MyUserIn.prototype['bio'] = undefined;

/**
 * @member {String} first_name
 */
MyUserIn.prototype['first_name'] = undefined;

/**
 * @member {String} last_name
 */
MyUserIn.prototype['last_name'] = undefined;

/**
 * @member {String} picture_object_name
 */
MyUserIn.prototype['picture_object_name'] = undefined;

/**
 * @member {module:model/UserHelpers} helpers
 */
MyUserIn.prototype['helpers'] = undefined;

/**
 * @member {module:model/UserIntro} intro
 */
MyUserIn.prototype['intro'] = undefined;

/**
 * @member {Boolean} newsletter
 */
MyUserIn.prototype['newsletter'] = undefined;






export default MyUserIn;

