/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserIntro model module.
 * @module model/UserIntro
 * @version 1.0.0
 */
class UserIntro {
    /**
     * Constructs a new <code>UserIntro</code>.
     * @alias module:model/UserIntro
     * @param datalake {Object} 
     * @param dataset {Object} 
     * @param home {Boolean} 
     * @param experiment {Object} 
     * @param project {Object} 
     * @param firstSteps {Object} 
     */
    constructor(datalake, dataset, home, experiment, project, firstSteps) { 
        
        UserIntro.initialize(this, datalake, dataset, home, experiment, project, firstSteps);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, datalake, dataset, home, experiment, project, firstSteps) { 
        obj['datalake'] = datalake;
        obj['dataset'] = dataset;
        obj['home'] = home;
        obj['experiment'] = experiment;
        obj['project'] = project;
        obj['first_steps'] = firstSteps;
    }

    /**
     * Constructs a <code>UserIntro</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserIntro} obj Optional instance to populate.
     * @return {module:model/UserIntro} The populated <code>UserIntro</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserIntro();

            if (data.hasOwnProperty('datalake')) {
                obj['datalake'] = ApiClient.convertToType(data['datalake'], Object);
            }
            if (data.hasOwnProperty('dataset')) {
                obj['dataset'] = ApiClient.convertToType(data['dataset'], Object);
            }
            if (data.hasOwnProperty('home')) {
                obj['home'] = ApiClient.convertToType(data['home'], 'Boolean');
            }
            if (data.hasOwnProperty('experiment')) {
                obj['experiment'] = ApiClient.convertToType(data['experiment'], Object);
            }
            if (data.hasOwnProperty('project')) {
                obj['project'] = ApiClient.convertToType(data['project'], Object);
            }
            if (data.hasOwnProperty('first_steps')) {
                obj['first_steps'] = ApiClient.convertToType(data['first_steps'], Object);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UserIntro</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserIntro</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of UserIntro.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }

        return true;
    }


}

UserIntro.RequiredProperties = ["datalake", "dataset", "home", "experiment", "project", "first_steps"];

/**
 * @member {Object} datalake
 */
UserIntro.prototype['datalake'] = undefined;

/**
 * @member {Object} dataset
 */
UserIntro.prototype['dataset'] = undefined;

/**
 * @member {Boolean} home
 */
UserIntro.prototype['home'] = undefined;

/**
 * @member {Object} experiment
 */
UserIntro.prototype['experiment'] = undefined;

/**
 * @member {Object} project
 */
UserIntro.prototype['project'] = undefined;

/**
 * @member {Object} first_steps
 */
UserIntro.prototype['first_steps'] = undefined;






export default UserIntro;

