/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserHelpers model module.
 * @module model/UserHelpers
 * @version 1.0.0
 */
class UserHelpers {
    /**
     * Constructs a new <code>UserHelpers</code>.
     * @alias module:model/UserHelpers
     * @param intro {Boolean} 
     * @param brush {Boolean} 
     * @param ruller {Boolean} 
     * @param leftBrush {Boolean} 
     * @param leftRuller {Boolean} 
     * @param _switch {Boolean} 
     * @param contextual {Boolean} 
     */
    constructor(intro, brush, ruller, leftBrush, leftRuller, _switch, contextual) { 
        
        UserHelpers.initialize(this, intro, brush, ruller, leftBrush, leftRuller, _switch, contextual);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, intro, brush, ruller, leftBrush, leftRuller, _switch, contextual) { 
        obj['intro'] = intro;
        obj['brush'] = brush;
        obj['ruller'] = ruller;
        obj['left_brush'] = leftBrush;
        obj['left_ruller'] = leftRuller;
        obj['switch'] = _switch;
        obj['contextual'] = contextual;
    }

    /**
     * Constructs a <code>UserHelpers</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserHelpers} obj Optional instance to populate.
     * @return {module:model/UserHelpers} The populated <code>UserHelpers</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserHelpers();

            if (data.hasOwnProperty('intro')) {
                obj['intro'] = ApiClient.convertToType(data['intro'], 'Boolean');
            }
            if (data.hasOwnProperty('brush')) {
                obj['brush'] = ApiClient.convertToType(data['brush'], 'Boolean');
            }
            if (data.hasOwnProperty('ruller')) {
                obj['ruller'] = ApiClient.convertToType(data['ruller'], 'Boolean');
            }
            if (data.hasOwnProperty('left_brush')) {
                obj['left_brush'] = ApiClient.convertToType(data['left_brush'], 'Boolean');
            }
            if (data.hasOwnProperty('left_ruller')) {
                obj['left_ruller'] = ApiClient.convertToType(data['left_ruller'], 'Boolean');
            }
            if (data.hasOwnProperty('switch')) {
                obj['switch'] = ApiClient.convertToType(data['switch'], 'Boolean');
            }
            if (data.hasOwnProperty('contextual')) {
                obj['contextual'] = ApiClient.convertToType(data['contextual'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UserHelpers</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserHelpers</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of UserHelpers.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }

        return true;
    }


}

UserHelpers.RequiredProperties = ["intro", "brush", "ruller", "left_brush", "left_ruller", "switch", "contextual"];

/**
 * @member {Boolean} intro
 */
UserHelpers.prototype['intro'] = undefined;

/**
 * @member {Boolean} brush
 */
UserHelpers.prototype['brush'] = undefined;

/**
 * @member {Boolean} ruller
 */
UserHelpers.prototype['ruller'] = undefined;

/**
 * @member {Boolean} left_brush
 */
UserHelpers.prototype['left_brush'] = undefined;

/**
 * @member {Boolean} left_ruller
 */
UserHelpers.prototype['left_ruller'] = undefined;

/**
 * @member {Boolean} switch
 */
UserHelpers.prototype['switch'] = undefined;

/**
 * @member {Boolean} contextual
 */
UserHelpers.prototype['contextual'] = undefined;






export default UserHelpers;

