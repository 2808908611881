/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import TimeCursorBehavior from './TimeCursorBehavior';

/**
 * The PreferencesOut model module.
 * @module model/PreferencesOut
 * @version 1.0.0
 */
class PreferencesOut {
    /**
     * Constructs a new <code>PreferencesOut</code>.
     * @alias module:model/PreferencesOut
     * @param autoMoveNextAsset {Boolean} 
     * @param displayAnnotationLabels {Boolean} 
     * @param trackDefaultDuration {Number} 
     * @param displaySkipPlayerControls {Boolean} 
     * @param displayStepPlayerControls {Boolean} 
     * @param enableInterpolation {Boolean} 
     * @param displayInterpolationMotionPath {Boolean} 
     * @param timeCursorBehavior {module:model/TimeCursorBehavior} 
     * @param trackingDefaultDuration {Number} 
     * @param enableAutoRedraw {Boolean} 
     * @param polygonSimplifyToleranceFactor {Number} 
     * @param enableCache {Boolean} 
     * @param cacheSize {Number} 
     * @param shapeOpacity {Number} 
     */
    constructor(autoMoveNextAsset, displayAnnotationLabels, trackDefaultDuration, displaySkipPlayerControls, displayStepPlayerControls, enableInterpolation, displayInterpolationMotionPath, timeCursorBehavior, trackingDefaultDuration, enableAutoRedraw, polygonSimplifyToleranceFactor, enableCache, cacheSize, shapeOpacity) { 
        
        PreferencesOut.initialize(this, autoMoveNextAsset, displayAnnotationLabels, trackDefaultDuration, displaySkipPlayerControls, displayStepPlayerControls, enableInterpolation, displayInterpolationMotionPath, timeCursorBehavior, trackingDefaultDuration, enableAutoRedraw, polygonSimplifyToleranceFactor, enableCache, cacheSize, shapeOpacity);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, autoMoveNextAsset, displayAnnotationLabels, trackDefaultDuration, displaySkipPlayerControls, displayStepPlayerControls, enableInterpolation, displayInterpolationMotionPath, timeCursorBehavior, trackingDefaultDuration, enableAutoRedraw, polygonSimplifyToleranceFactor, enableCache, cacheSize, shapeOpacity) { 
        obj['auto_move_next_asset'] = autoMoveNextAsset;
        obj['display_annotation_labels'] = displayAnnotationLabels;
        obj['track_default_duration'] = trackDefaultDuration;
        obj['display_skip_player_controls'] = displaySkipPlayerControls;
        obj['display_step_player_controls'] = displayStepPlayerControls;
        obj['enable_interpolation'] = enableInterpolation;
        obj['display_interpolation_motion_path'] = displayInterpolationMotionPath;
        obj['time_cursor_behavior'] = timeCursorBehavior;
        obj['tracking_default_duration'] = trackingDefaultDuration;
        obj['enable_auto_redraw'] = enableAutoRedraw;
        obj['polygon_simplify_tolerance_factor'] = polygonSimplifyToleranceFactor;
        obj['enable_cache'] = enableCache;
        obj['cache_size'] = cacheSize;
        obj['shape_opacity'] = shapeOpacity;
    }

    /**
     * Constructs a <code>PreferencesOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PreferencesOut} obj Optional instance to populate.
     * @return {module:model/PreferencesOut} The populated <code>PreferencesOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PreferencesOut();

            if (data.hasOwnProperty('auto_move_next_asset')) {
                obj['auto_move_next_asset'] = ApiClient.convertToType(data['auto_move_next_asset'], 'Boolean');
            }
            if (data.hasOwnProperty('display_annotation_labels')) {
                obj['display_annotation_labels'] = ApiClient.convertToType(data['display_annotation_labels'], 'Boolean');
            }
            if (data.hasOwnProperty('track_default_duration')) {
                obj['track_default_duration'] = ApiClient.convertToType(data['track_default_duration'], 'Number');
            }
            if (data.hasOwnProperty('display_skip_player_controls')) {
                obj['display_skip_player_controls'] = ApiClient.convertToType(data['display_skip_player_controls'], 'Boolean');
            }
            if (data.hasOwnProperty('display_step_player_controls')) {
                obj['display_step_player_controls'] = ApiClient.convertToType(data['display_step_player_controls'], 'Boolean');
            }
            if (data.hasOwnProperty('enable_interpolation')) {
                obj['enable_interpolation'] = ApiClient.convertToType(data['enable_interpolation'], 'Boolean');
            }
            if (data.hasOwnProperty('display_interpolation_motion_path')) {
                obj['display_interpolation_motion_path'] = ApiClient.convertToType(data['display_interpolation_motion_path'], 'Boolean');
            }
            if (data.hasOwnProperty('time_cursor_behavior')) {
                obj['time_cursor_behavior'] = TimeCursorBehavior.constructFromObject(data['time_cursor_behavior']);
            }
            if (data.hasOwnProperty('tracking_default_duration')) {
                obj['tracking_default_duration'] = ApiClient.convertToType(data['tracking_default_duration'], 'Number');
            }
            if (data.hasOwnProperty('enable_auto_redraw')) {
                obj['enable_auto_redraw'] = ApiClient.convertToType(data['enable_auto_redraw'], 'Boolean');
            }
            if (data.hasOwnProperty('polygon_simplify_tolerance_factor')) {
                obj['polygon_simplify_tolerance_factor'] = ApiClient.convertToType(data['polygon_simplify_tolerance_factor'], 'Number');
            }
            if (data.hasOwnProperty('enable_cache')) {
                obj['enable_cache'] = ApiClient.convertToType(data['enable_cache'], 'Boolean');
            }
            if (data.hasOwnProperty('cache_size')) {
                obj['cache_size'] = ApiClient.convertToType(data['cache_size'], 'Number');
            }
            if (data.hasOwnProperty('shape_opacity')) {
                obj['shape_opacity'] = ApiClient.convertToType(data['shape_opacity'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PreferencesOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PreferencesOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of PreferencesOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }

        return true;
    }


}

PreferencesOut.RequiredProperties = ["auto_move_next_asset", "display_annotation_labels", "track_default_duration", "display_skip_player_controls", "display_step_player_controls", "enable_interpolation", "display_interpolation_motion_path", "time_cursor_behavior", "tracking_default_duration", "enable_auto_redraw", "polygon_simplify_tolerance_factor", "enable_cache", "cache_size", "shape_opacity"];

/**
 * @member {Boolean} auto_move_next_asset
 */
PreferencesOut.prototype['auto_move_next_asset'] = undefined;

/**
 * @member {Boolean} display_annotation_labels
 */
PreferencesOut.prototype['display_annotation_labels'] = undefined;

/**
 * @member {Number} track_default_duration
 */
PreferencesOut.prototype['track_default_duration'] = undefined;

/**
 * @member {Boolean} display_skip_player_controls
 */
PreferencesOut.prototype['display_skip_player_controls'] = undefined;

/**
 * @member {Boolean} display_step_player_controls
 */
PreferencesOut.prototype['display_step_player_controls'] = undefined;

/**
 * @member {Boolean} enable_interpolation
 */
PreferencesOut.prototype['enable_interpolation'] = undefined;

/**
 * @member {Boolean} display_interpolation_motion_path
 */
PreferencesOut.prototype['display_interpolation_motion_path'] = undefined;

/**
 * @member {module:model/TimeCursorBehavior} time_cursor_behavior
 */
PreferencesOut.prototype['time_cursor_behavior'] = undefined;

/**
 * @member {Number} tracking_default_duration
 */
PreferencesOut.prototype['tracking_default_duration'] = undefined;

/**
 * @member {Boolean} enable_auto_redraw
 */
PreferencesOut.prototype['enable_auto_redraw'] = undefined;

/**
 * @member {Number} polygon_simplify_tolerance_factor
 */
PreferencesOut.prototype['polygon_simplify_tolerance_factor'] = undefined;

/**
 * @member {Boolean} enable_cache
 */
PreferencesOut.prototype['enable_cache'] = undefined;

/**
 * @member {Number} cache_size
 */
PreferencesOut.prototype['cache_size'] = undefined;

/**
 * @member {Number} shape_opacity
 */
PreferencesOut.prototype['shape_opacity'] = undefined;






export default PreferencesOut;

