import { uploadFile } from "@/js/utils/upload";
import PxlUserApi from "openapi-client/src/api/UserApi.js";
import client from "@/js/api/client.js";

class UserApi extends PxlUserApi {
  async uploadProfilePicture(picture, { onProgress, signal } = {}) {
    const uploadData = await this.generatePresignedUrlProfilePicture({ content_type: picture.type });
    await uploadFile(picture, uploadData, { onProgress, signal });
    return this.partialUpdateMyUser({ picture_object_name: uploadData.object_name });
  }
}

export default new UserApi(client);
