/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ClientTypeEnum from './ClientTypeEnum';
import PresignedUrlData from './PresignedUrlData';

/**
 * The GeneratePresignedUrlOut model module.
 * @module model/GeneratePresignedUrlOut
 * @version 1.0.0
 */
class GeneratePresignedUrlOut {
    /**
     * Constructs a new <code>GeneratePresignedUrlOut</code>.
     * @alias module:model/GeneratePresignedUrlOut
     * @param objectName {String} 
     * @param presignedUrlData {module:model/PresignedUrlData} 
     * @param clientType {module:model/ClientTypeEnum} 
     */
    constructor(objectName, presignedUrlData, clientType) { 
        
        GeneratePresignedUrlOut.initialize(this, objectName, presignedUrlData, clientType);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, objectName, presignedUrlData, clientType) { 
        obj['object_name'] = objectName;
        obj['presigned_url_data'] = presignedUrlData;
        obj['client_type'] = clientType;
    }

    /**
     * Constructs a <code>GeneratePresignedUrlOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/GeneratePresignedUrlOut} obj Optional instance to populate.
     * @return {module:model/GeneratePresignedUrlOut} The populated <code>GeneratePresignedUrlOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GeneratePresignedUrlOut();

            if (data.hasOwnProperty('object_name')) {
                obj['object_name'] = ApiClient.convertToType(data['object_name'], 'String');
            }
            if (data.hasOwnProperty('presigned_url_data')) {
                obj['presigned_url_data'] = PresignedUrlData.constructFromObject(data['presigned_url_data']);
            }
            if (data.hasOwnProperty('client_type')) {
                obj['client_type'] = ClientTypeEnum.constructFromObject(data['client_type']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>GeneratePresignedUrlOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GeneratePresignedUrlOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of GeneratePresignedUrlOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['object_name'] && !(typeof data['object_name'] === 'string' || data['object_name'] instanceof String)) {
            throw new Error("Expected the field `object_name` to be a primitive type in the JSON string but got " + data['object_name']);
        }
        // validate the optional field `presigned_url_data`
        if (data['presigned_url_data']) { // data not null
          PresignedUrlData.validateJSON(data['presigned_url_data']);
        }

        return true;
    }


}

GeneratePresignedUrlOut.RequiredProperties = ["object_name", "presigned_url_data", "client_type"];

/**
 * @member {String} object_name
 */
GeneratePresignedUrlOut.prototype['object_name'] = undefined;

/**
 * @member {module:model/PresignedUrlData} presigned_url_data
 */
GeneratePresignedUrlOut.prototype['presigned_url_data'] = undefined;

/**
 * @member {module:model/ClientTypeEnum} client_type
 */
GeneratePresignedUrlOut.prototype['client_type'] = undefined;






export default GeneratePresignedUrlOut;

