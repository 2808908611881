/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import TimeCursorBehavior from './TimeCursorBehavior';

/**
 * The PreferencesIn model module.
 * @module model/PreferencesIn
 * @version 1.0.0
 */
class PreferencesIn {
    /**
     * Constructs a new <code>PreferencesIn</code>.
     * @alias module:model/PreferencesIn
     */
    constructor() { 
        
        PreferencesIn.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PreferencesIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PreferencesIn} obj Optional instance to populate.
     * @return {module:model/PreferencesIn} The populated <code>PreferencesIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PreferencesIn();

            if (data.hasOwnProperty('auto_move_next_asset')) {
                obj['auto_move_next_asset'] = ApiClient.convertToType(data['auto_move_next_asset'], 'Boolean');
            }
            if (data.hasOwnProperty('display_annotation_labels')) {
                obj['display_annotation_labels'] = ApiClient.convertToType(data['display_annotation_labels'], 'Boolean');
            }
            if (data.hasOwnProperty('track_default_duration')) {
                obj['track_default_duration'] = ApiClient.convertToType(data['track_default_duration'], 'Number');
            }
            if (data.hasOwnProperty('display_skip_player_controls')) {
                obj['display_skip_player_controls'] = ApiClient.convertToType(data['display_skip_player_controls'], 'Boolean');
            }
            if (data.hasOwnProperty('display_step_player_controls')) {
                obj['display_step_player_controls'] = ApiClient.convertToType(data['display_step_player_controls'], 'Boolean');
            }
            if (data.hasOwnProperty('enable_interpolation')) {
                obj['enable_interpolation'] = ApiClient.convertToType(data['enable_interpolation'], 'Boolean');
            }
            if (data.hasOwnProperty('display_interpolation_motion_path')) {
                obj['display_interpolation_motion_path'] = ApiClient.convertToType(data['display_interpolation_motion_path'], 'Boolean');
            }
            if (data.hasOwnProperty('time_cursor_behavior')) {
                obj['time_cursor_behavior'] = TimeCursorBehavior.constructFromObject(data['time_cursor_behavior']);
            }
            if (data.hasOwnProperty('tracking_default_duration')) {
                obj['tracking_default_duration'] = ApiClient.convertToType(data['tracking_default_duration'], 'Number');
            }
            if (data.hasOwnProperty('enable_auto_redraw')) {
                obj['enable_auto_redraw'] = ApiClient.convertToType(data['enable_auto_redraw'], 'Boolean');
            }
            if (data.hasOwnProperty('polygon_simplify_tolerance_factor')) {
                obj['polygon_simplify_tolerance_factor'] = ApiClient.convertToType(data['polygon_simplify_tolerance_factor'], 'Number');
            }
            if (data.hasOwnProperty('enable_cache')) {
                obj['enable_cache'] = ApiClient.convertToType(data['enable_cache'], 'Boolean');
            }
            if (data.hasOwnProperty('cache_size')) {
                obj['cache_size'] = ApiClient.convertToType(data['cache_size'], 'Number');
            }
            if (data.hasOwnProperty('shape_opacity')) {
                obj['shape_opacity'] = ApiClient.convertToType(data['shape_opacity'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PreferencesIn</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PreferencesIn</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * @member {Boolean} auto_move_next_asset
 */
PreferencesIn.prototype['auto_move_next_asset'] = undefined;

/**
 * @member {Boolean} display_annotation_labels
 */
PreferencesIn.prototype['display_annotation_labels'] = undefined;

/**
 * @member {Number} track_default_duration
 */
PreferencesIn.prototype['track_default_duration'] = undefined;

/**
 * @member {Boolean} display_skip_player_controls
 */
PreferencesIn.prototype['display_skip_player_controls'] = undefined;

/**
 * @member {Boolean} display_step_player_controls
 */
PreferencesIn.prototype['display_step_player_controls'] = undefined;

/**
 * @member {Boolean} enable_interpolation
 */
PreferencesIn.prototype['enable_interpolation'] = undefined;

/**
 * @member {Boolean} display_interpolation_motion_path
 */
PreferencesIn.prototype['display_interpolation_motion_path'] = undefined;

/**
 * @member {module:model/TimeCursorBehavior} time_cursor_behavior
 */
PreferencesIn.prototype['time_cursor_behavior'] = undefined;

/**
 * @member {Number} tracking_default_duration
 */
PreferencesIn.prototype['tracking_default_duration'] = undefined;

/**
 * @member {Boolean} enable_auto_redraw
 */
PreferencesIn.prototype['enable_auto_redraw'] = undefined;

/**
 * @member {Number} polygon_simplify_tolerance_factor
 */
PreferencesIn.prototype['polygon_simplify_tolerance_factor'] = undefined;

/**
 * @member {Boolean} enable_cache
 */
PreferencesIn.prototype['enable_cache'] = undefined;

/**
 * @member {Number} cache_size
 */
PreferencesIn.prototype['cache_size'] = undefined;

/**
 * @member {Number} shape_opacity
 */
PreferencesIn.prototype['shape_opacity'] = undefined;






export default PreferencesIn;

